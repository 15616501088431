import React, { ReactElement, FC, useEffect, useState } from "react";
import { Box, Pagination, PaginationItem } from "@mui/material";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setBlogs } from "../../../store/slices/blogSlice";
import { fetchBlogs } from "../../../store/slices/api";
import moment from "moment";
import blogBannerImg from "../../../images/blogs_banner_image.png";
import blogDate from "../../../images/blogDate.png";
import bullets from "../../../images/bullets_top_bottom.png";
import Skeleton from "@mui/material/Skeleton";
import "../Blogs.scss";

const Blogs: FC<any> = (): ReactElement => {
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;
  useEffect(() => {
    const truncateText = (element: any, maxCharacters: number) => {
      const originalText: string = element.textContent || "";
      if (originalText.length > maxCharacters) {
        const truncatedText = originalText.substring(0, maxCharacters) + "...";
        element.textContent = truncatedText;
      }
    };

    const textElements = document.querySelectorAll(".blogTitle");
    textElements.forEach((element) => {
      return truncateText(element, 50); // Adjust the character limit as needed
    });
  }, []);

  const dispatch = useDispatch();
  const blogs = useSelector((state: any) => state.blogSlice.blogs);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchBlogs("blogs/list_blogs/");
        document.cookie = `blogs=${JSON.stringify(data.posts)}`;
        dispatch(setBlogs(data.posts));
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [dispatch]);

  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
    window.scrollTo(0, 0);
  };

  const getCurrentPageBlogs = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return blogs.slice(startIndex, endIndex);
  };

  return (
    <>
      <Container fluid className="bannerBlog row-gutter-x-y-none">
        <Container className="gutter-Spacing-0 height-100">
          <Row className="padding-zero margin-none row-gutter-x-y-none cardPadding height-100">
            <Col xs={12} sm={12} md={4} lg={4} className="padding-zero">
              <div className="hero-banner-blog">
                <h1
                  className="bannerTitle text-align-left"
                  data-aos="fade-right"
                  data-aos-easing="linear"
                  data-aos-duration="1500"
                  data-aos-delay="500"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-once="false"
                >
                  Blogs
                </h1>
              </div>
            </Col>
            <Col xs={12} sm={12} md={8} lg={8} className="padding-zero">
              <Image
                data-aos="fade-left"
                data-aos-easing="linear"
                data-aos-duration="1500"
                data-aos-delay="500"
                data-aos-anchor-placement="top-bottom"
                data-aos-once="false"
                className="d-block w-100"
                src={blogBannerImg}
                alt="First slide"
                fluid
                style={{ objectFit: "cover" }}
              />
            </Col>
          </Row>
        </Container>
      </Container>
      <div className="blogsSection_BG relativePositionCSS">
        <Image
          className="bullet_top absolutePositionCSS"
          src={bullets}
          alt="bullet_top"
        />
        {loading ? (
          <Box
            className="blogs_loading_div"
          >
            <Container className="">
              {/* <br /> */}
              <Row className="alignItemsCenter row-gutter-x-y-none">
                {[...Array(6)].map((_, index) => (
                  <Col
                    key={index}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                    className={`padding-12px`}
                  >
                    <div className="blogsCard" style={{ boxShadow: "unset" }}>
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={118}
                      />
                      <br />
                      <Skeleton width="50%" />
                      <br />
                      <Skeleton width="100%" />
                      <Skeleton width="90%" />
                    </div>
                  </Col>
                ))}
              </Row>
              {/* <br /> */}
            </Container>
            {/* <CircularProgress /> */}
          </Box>
        ) : (
          <Container className="">
            <Row className="alignItemsCenter row-gutter-x-y-none">
              {getCurrentPageBlogs().map((blog: any, index: number) => (
                <Col
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  key={blog.id}
                  className={`padding-12px slideInUp`}
                  style={{
                    animationDuration: `${1000 + index * 500}ms`,
                  }}
                >
                  <Link

                    to={`/blogs/${blog.slug}`}
                    color="transparent"
                    style={{ textDecoration: "none" }}
                    onClick={handleClick}
                  >
                    <div className="blogsCard" key={blog.id}>
                      <Card.Body>
                        <div className="blogImage">
                          <Image


                            className="card-icon1 blogImage_div"

                            src={blog.cover_file ?? blog.cover_file}
                            alt="Blog-Image"
                          />
                        </div>
                        <div className="blogInfoSection">
                          <div className="infoProfile d-flex align-items-center">
                            <Image

                              className="card-icon1 blogImage_div"
                              src={blogDate}
                              alt="CalenderImg"
                            />
                            <span>
                              {" "}
                              {moment(blog.created_at).format(
                                "MMM DD, YYYY"
                              )}{" "}
                            </span>
                          </div>
                        </div>
                        <Card.Text className="blogTitle">
                          {blog.title}
                        </Card.Text>
                      </Card.Body>
                    </div>
                  </Link>
                </Col>
              ))}
            </Row>
            <Box className="pagination_div">
              <Pagination
                className="blogsPagination"
                count={Math.ceil(blogs.length / itemsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
                boundaryCount={1}
                siblingCount={1}
                renderItem={(item) => (
                  <PaginationItem
                    {...item}
                    onClick={(event) => {
                      handleClick();
                      if (item.page !== null && item.page !== currentPage) {
                        handlePageChange(event, item.page);
                        window.scrollTo(0, 0);
                        if (item.type === "previous" || item.type === "next") {
                          console.log(item.type);
                          window.scrollTo(0, 0);
                        }
                      }
                    }}
                    components={{
                      previous: (props) => (
                        <Box
                          className="previous_next_btn"
                          onClick={(event: any) => {
                            handlePageChange(event, currentPage - 1);
                            window.scrollTo(0, 0);
                          }}
                        >
                          <span>Previous</span>
                        </Box>
                      ),
                      next: () => (
                        <Box
                          className="previous_next_btn"
                          onClick={(event: any) => {
                            handlePageChange(event, currentPage + 1);
                            window.scrollTo(0, 0);
                          }}
                        >
                          <span>Next</span>
                        </Box>
                      ),
                    }}
                  />
                )}
              />
            </Box>
          </Container>
        )}
        <Image className="bullet_bottom" src={bullets} alt="bullet_top" />
      </div>
    </>
  );
};

export default Blogs;
